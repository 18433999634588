<template>
  <div class="formAddRoom">

    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body p-3 pt-4">
        <v-card style="box-shadow: none">
          <v-tabs
              v-model="tab"
              background-color="deep-purple accent-4"
              centered
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">

              <i class="menu-icon  flaticon-notes"></i>
              Thông tin tài khoản
            </v-tab>

            <v-tab href="#tab-2">

              <i class="menu-icon flaticon-interface-9"></i>
              Đổi mật khẩu
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
                value="tab-1"
            >
              <v-card flat>
                <v-card-text>
                  <form @next-tab="tab = 'tab-2'" class="form">
                    <!--begin::Body-->
                    <div class="card-body body-800">
                      <!--<div class="row">
                        <label class="col-xl-3 text-left col-lg-3 col-form-label title-profile-ks pr-0"
                               style="padding-top: 100px; padding-right: 50px; white-space: nowrap"
                        >Ảnh đại diện</label>
                        <div class="col-lg-3 col-xl-3">
                          <div class="image-input image-input-outline" id="kt_profile_avatar">
                            <div
                                class="image-input-wrapper"
                                :style="{ backgroundImage: `url(${photo})` }"
                            ></div>
                            <label
                                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="change"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Change avatar"
                            >
                              <i class="fa fa-pen icon-sm text-muted"></i>
                              <input
                                  type="file"
                                  name="profile_avatar"
                                  accept=".png, .jpg, .jpeg"
                                  @change="onFileChange($event)"
                              />
                              <input type="hidden" name="profile_avatar_remove" />
                            </label>
                            <span
                                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="cancel"
                                data-toggle="tooltip"
                                title="Cancel avatar"
                            >
              <i class="ki ki-bold-close icon-xs text-muted"></i>
            </span>
                                         </span>
                          </div>
                        </div>
                      </div> -->
                      <b-row class="form-group">
                        <b-col lg="12" xl="12">
                          <b-form-group id="input-group-1" label="Tên khách sạn" label-for="input-1">
                            <b-form-input
                                id="input-1"
                                v-model="form.hotel_name"
                                placeholder="Nhập tên khách sạn"
                                type="text"
                                trim
                            ></b-form-input>

                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col lg="12" xl="12">
                          <b-form-group id="input-group-2" label="Số điện thoại" label-for="input-2">
                            <b-form-input
                                id="input-2"
                                type="number"
                                v-model="form.phone"
                                placeholder="Nhập số điện thoại"
                                aria-describedby="input-live-help input-live-feedback"
                                trim
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col lg="12" xl="12">
                          <b-form-group id="input-group-3" label="Thư điện tử" label-for="input-3">
                            <b-form-input
                                id="input-3"
                                type="text"
                                v-model="form.email"
                                placeholder="Nhập thư điện tử"
                                aria-describedby="input-live-help input-live-feedback"
                                trim
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col lg="6" xl="6">
                          <b-form-group id="input-group-4" label="Tỉnh thành" label-for="input-4">
                            <b-form-select
                                id="input-4"
                                v-model="form.province_id"
                                :options="arrayCity"
                                aria-describedby="input-live-help input-live-feedback"
                                trim
                                @change="changeCity"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" xl="6"
                        >
                          <b-form-group id="input-group-5" label="Quận huyện" label-for="input-5">
                            <b-form-select
                                id="input-5"
                                v-model="form.district_id"
                                :options="arrayDistrict"
                                aria-describedby="input-live-help input-live-feedback"
                                @change="changeDistrict"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col lg="12" xl="12" >
                          <b-form-group id="input-group-6" label="Xã phường" label-for="input-6">
                            <b-form-select
                                id="input-6"
                                v-model="form.ward_id"
                                :options="arrayWard"
                                aria-describedby="input-live-help input-live-feedback"
                                @change="changeWard"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col llg="12" xl="12">
                          <b-form-group id="input-group-7" label="Địa chỉ" label-for="input-7">
                            <b-form-input
                                id="input-7"
                                type="text"
                                v-model="form.address"
                                placeholder="Địa chỉ"
                                aria-describedby="input-live-help input-live-feedback"
                                trim
                            ></b-form-input>
                          </b-form-group>

                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col lg="12" xl="12">
                          <b-form-group id="input-group-8" label="Chủ khách sạn" label-for="input-8">
                            <b-form-input
                                id="input-8"
                                type="text"
                                v-model="form.owner_name"
                                placeholder="Nhập chủ khách sạn"
                                aria-describedby="input-live-help input-live-feedback"
                                trim
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <!--end::Body-->
                  </form>
                  <hr>
                  <div class="clearfix body-800">
                    <div class="float-right " style="margin-right: 30px;">
                      <b-button type="button" @click="updateUser" class="btnBase"> Cập nhật</b-button>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-2"
            >
              <v-card flat>
                <v-card-text>
                  <form @back-tab="tab = 'tab-1'" class="form">
                    <!--begin::Body-->
                    <div class="card-body body-500">
                      <p class=" title-pass-ks mb-6">Thay đổi hoặc khôi phục mật khẩu</p>
                      <b-row class="form-group">
                        <b-col lg="12" xl="12">
                          <b-form-group id="input-group-9" label="Mật khẩu hiện tại" label-for="input-9">
                            <b-form-input
                                id="input-9"
                                type="password"
                                v-model="password.old_password"
                                placeholder="Nhập mật khẩu hiện tại"
                                aria-describedby="input-live-help input-live-feedback"
                                trim
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col lg="12" xl="12">
                          <b-form-group id="input-group-10" label="Mật khẩu mới" label-for="input-10">
                            <b-form-input
                                id="input-10"
                                type="password"
                                v-model="password.new_password"
                                :state="messageError.new_password.state"
                                placeholder="Nhập mật khẩu mới"
                                aria-describedby="input-live-help input-live-feedback"
                                trim
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-feedback">
                              {{ messageError.new_password.text }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col lg="12" xl="12">
                          <b-form-group id="input-group-11" label="Nhập lại mật khẩu" label-for="input-11">
                            <b-form-input
                                id="input-11"
                                type="password"
                                v-model="password.new_password2"
                                :state="messageError.new_password.state"
                                placeholder="Nhập lại mật khẩu"
                                aria-describedby="input-live-help input-live-feedback"
                                trim
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-feedback">
                              {{ messageError.new_password.text }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <!--end::Body-->
                  </form>
                  <hr>
                  <div class="clearfix body-500">
                    <div class="float-right" style="margin-right: 30px;">
                      <b-button type="button" @click="changePassword" class="btnBase"> Cập nhật</b-button>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

        </v-card>
      </div>
    </div>
  </div>
  <!--end::Form-->
  <!--  </div>-->
</template>

<script>
import {mapGetters} from "vuex";
import {UPDATE_PERSONAL_INFO} from "@/core/services/store/profile.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import BaseService from "@/core/helper/base.service";
import ApiService from "@/core/services/api.service";
import {EventBus} from "@/view/pages/share/event-bus";
export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      tab: 'tab-1',
      form: {
        hotel_name: '',
        phone: '',
        email: '',
        address: '',
        owner_name: '',
        province_id: null,
        district_id: null,
        ward_id: null,
      },
      password: {
        old_password: '',
        new_password: '',
        new_password2: ''
      },
      messageError: {
        new_password: {
          text: '',
          state: null
        },
        province_id: {
          text: '',
          state: null
        },
        district_id: {
          text: '',
          state: null
        },
      },
      floors: [{text: 'Chọn tầng', value: null}, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
      arrayCity: [],
      arrayWard: [],
      arrayDistrict: []
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Cập nhật thông tin khách sạn"}]);
  },
  created() {
    this.getApiUser();
  },
  methods: {

    changePassword() {
      // if (this.)
      if (this.password.new_password === this.password.new_password2) {
        this.messageError.new_password.state = null
        this.updatePassword();
      } else {
        this.messageError.new_password.text = 'Mật khẩu mới phải trùng nhau'
        this.messageError.new_password.state = false
      }
    },
    getApiUser() {
      ApiService.get("me").then((res) => {
        this.form = res.data.data;
        this.cityApi();
        this.districtApi(this.form.province_id);
        this.wardApi(this.form.district_id);
      })

    },
    updatePassword() {
      let form = {
        old_password: this.password.old_password,
        new_password: this.password.new_password
      }
      ApiService.put("me/passwd", form).then((response) => {
        if (response.data.code === 1) {
          BaseService.makeToast('success', 'Cập nhật mật khẩu thành công', this)
        } else {
          BaseService.makeToast('danger', response.data.message, this)
        }
      }).catch(({err}) => {
        console.log(err)
        BaseService.makeToast('danger', err.response.data.message, this)
      });
    },
    updateUser() {
      let form = this.form
      ApiService.put("me", form).then((response) => {
        if (response.data.code === 1) {
          EventBus.$emit('component-a-clicked', 'eventUpdateProfile');
          BaseService.makeToast('success', 'Cập nhật thông tin tài khoản thành công', this)
        } else {
          BaseService.makeToast('danger', response.data.message, this)
        }
      }).catch(({err}) => {
        console.log(err)
        BaseService.makeToast('danger', err.response.data.message, this)
      });
    },
    districtApi(id) {
      let _params = {
        province_id: id,
      }
      BaseService.getDistrict(_params).then((res) => {
        let arr = []
        res.data.data.items.map((res2) => {
          arr.push({
            text: res2.name,
            value: res2.id
          })
        })
        this.arrayDistrict = [{text: 'Quận huyện', value: null}, ...arr]
      })
    },
    wardApi(id) {
      let _params = {
        district_id: id,
      }
      BaseService.getWard(_params).then((res) => {
        let arr = []
        res.data.data.items.map((res2) => {
          arr.push({
            text: res2.name,
            value: res2.id
          })
        })
        this.arrayWard = [{text: 'Phường xã', value: null}, ...arr]

      })
    },
    cityApi() {
      BaseService.getCity().then((res) => {
        let arr = []
        res.data.data.items.map((res2) => {
          arr.push({
            text: res2.name,
            value: res2.id
          })
        })
        this.arrayCity = [{text: 'Tỉnh thành', value: null}, ...arr]
      })
    },
    changeCity() {
      if (this.form.province_id === null) {
        this.messageError.province_id.text = 'Tỉnh thành không được để trống'
        this.messageError.province_id.state = false
      } else {
        console.log('city')
        this.messageError.province_id.state = null;
        this.form.district_id = null;
        this.form.ward_id = null;
        this.districtApi(this.form.province_id)
      }
    },
    changeDistrict() {
      if (this.form.province_id === null) {
        this.messageError.province_id.text = 'Tỉnh thành không được để trống'
        this.messageError.province_id.state = false
      } else {
        this.wardApi(this.form.district_id)
        this.form.ward_id = null;
      }
    },
    changeWard() {
      if (this.form.district_id === null) {
        this.messageError.district_id.text = 'Quận huyện không được để trống'
        this.messageError.district_id.state = false
      } else {
        this.messageError.district_id.state = null
      }
    },
  },
};
</script>
<style lang="scss" scoped>
//.formAddRoom .theme--light.v-sheet i {
//  margin-right: 0 !important;
//}
.body-500 {
  max-width: 500px;
  margin: 0 auto;
}
.body-800 {
  max-width: 800px;
  margin: 0 auto;
}
</style>
